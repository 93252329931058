import {API_EVENTS} from "../lib/my-treepodia";
import videojs from "video.js";
import {TreepodiaPlayerEvents} from "../TreepodiaPlayer";

const Plugin = videojs.getPlugin('plugin');

export class TreepodiaEventsPlugin extends Plugin {
    constructor(player, options) {
        super(player, options);
        this.firstPlay = true;
        this.played25 = false;
        this.played50 = false;
        this.played75 = false;
        this.ended = false;

        this.mtTracker = options.mtTracker;
        this.gaTracker = options.gaTracker;

        player.on(TreepodiaPlayerEvents.OVERLAY_CLICK, (overlayButton) => {
            this.gaTracker.sendEvent(API_EVENTS.OVERLAY_CLICK, overlayButton.id)
        });

        player.on('play', () => {
            if (this.firstPlay) {
                this.mtTracker.sendEvent(API_EVENTS.PLAYER_PLAY)
                this.gaTracker.sendEvent(API_EVENTS.PLAYER_PLAY)
                this.firstPlay = false
            } else {
                this.mtTracker.sendEvent(API_EVENTS.PLAYER_RESUME)
                this.gaTracker.sendEvent(API_EVENTS.PLAYER_RESUME)
            }

        });

        player.on('pause', () => {
            if (player.currentTime() !== player.duration()) {
                this.mtTracker.sendEvent(API_EVENTS.PLAYER_PAUSE)
                this.gaTracker.sendEvent(API_EVENTS.PLAYER_PAUSE)
            }
        });

        player.on('ended', () => {
            if (!this.ended) {
                this.mtTracker.sendEvent(API_EVENTS.PLAYER_END)
                this.gaTracker.sendEvent(API_EVENTS.PLAYER_END)
                this.ended = true;
            }
        });

        player.on('timeupdate', () => {
            const percentViewed = Math.round(100 * player.currentTime() / player.duration());
            if (percentViewed > 25 && !this.played25) {
                this.mtTracker.sendEvent(API_EVENTS.PLAYER_25)
                this.gaTracker.sendEvent(API_EVENTS.PLAYER_25)
                this.played25 = true;
            }
            if (percentViewed > 50 && !this.played50) {
                this.mtTracker.sendEvent(API_EVENTS.PLAYER_50)
                this.gaTracker.sendEvent(API_EVENTS.PLAYER_50)
                this.played50 = true;
            }
            if (percentViewed > 75 && !this.played75) {
                this.mtTracker.sendEvent(API_EVENTS.PLAYER_75)
                this.gaTracker.sendEvent(API_EVENTS.PLAYER_75)
                this.played75 = true;
            }
        });
    }
}
