import './scss/treepodia-theme'
import 'regenerator-runtime/runtime'
import 'promise-polyfill/src/polyfill';
import {TreepodiaPlayer} from "./TreepodiaPlayer";

const DOMReady = function (fn) {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        if (document['addEventListener']) document.addEventListener("DOMContentLoaded", fn);
        else window.attachEvent('onload', fn)
    }
}

DOMReady(() => {
    window.TreepodiaPlayer = new TreepodiaPlayer();
})
