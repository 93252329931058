export class GoogleAnalyticsTracker {

    constructor() {
        this.account = null;
        this.sku = null;
        this.videoId = null;
        this.enabled = false;
        this.gaId = null;
    }

    setAccount(account) {
        this.account = account;
        return this
    }

    setSku(sku) {
        this.sku = sku;
        return this
    }

    setVideoId(videoId) {
        this.videoId = videoId;
        return this
    }

    /*
    * <!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-EMWMRFGDEY"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-EMWMRFGDEY');
</script>
    *
    * */

    setEnabled(isEnabled) {
        if (isEnabled && window.gtag) this.enabled = isEnabled;
        return this
    }

    setGaId(gaId) {
        if (this._isValidGaId(gaId)) {
            this.gaId = gaId;
            if (this.enabled) window.gtag('config', this.gaId);
            else {
                const gaScript = document.createElement('script')
                gaScript.async = true;
                gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
                document.body.appendChild(gaScript);
                window.dataLayer = window.dataLayer || [];
                window.gtag = function(){dataLayer.push(arguments);}
                window.gtag('js', new Date());
                window.gtag('config', gaId);
                this.enabled = true;
            }
        }
        return this
    }

    _isValidGaId(test) {
        return test &&
            typeof test === "string" &&
            test !== ''
            // &&
            // test.split('-').length === 3 &&
            // test.split('-')[0] === 'UA' //&&
            // test.split('-')[1].length === 7 &&
            // test.split('-')[2].length === 2
    }

    sendEvent(type, value) {
        if (this.enabled) {
            const params = {
                "event_category": 'treepodia-video',
                "event_label": this.sku
            };
            if (value !== undefined) params["value"] = value;
            window.gtag('event', type, params);
        }
    }
}
