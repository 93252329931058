import videojs from "video.js";
import * as uuid from "uuid";
import {TreepodiaPlayerEvents} from "../../TreepodiaPlayer";

const Component = videojs.getComponent('Component');

export class TreepodiaOverlayButtonComponent extends Component {
    constructor(player, options) {
        super(player, options);
        this.id = options.id || uuid.v4();
        this.startTime = options.startTime;
        this.endTime = options.endTime;
        if (this.startTime === undefined && this.endTime === undefined) {
            this.startTime = this.endTime = Math.ceil(player.duration());
        } else if (this.startTime === undefined && this.endTime !== undefined && parseInt(this.endTime) > 0) {
            this.startTime = 0;
            this.endTime = parseInt(this.endTime);
        } else if (this.endTime === undefined && this.startTime !== undefined && parseInt(this.startTime) >= 0 && parseInt(this.startTime) < player.duration()) {
            this.startTime = parseInt(this.startTime);
            this.endTime = Math.ceil(player.duration());
        }
        this.xMultiplier = options.buttonRect[0] / options.videoSize.width
        this.yMultiplier = options.buttonRect[1] / options.videoSize.height
        this.widthMultiplier = options.buttonRect[2] / options.videoSize.width
        this.heightMultiplier = options.buttonRect[3] / options.videoSize.height
        this.hide();
        this.transform(player.currentDimensions())
        if (options.debug) {
            this.addClass('debug');
        }
        this.on('click', () => {
            player.trigger(TreepodiaPlayerEvents.OVERLAY_CLICK, this);
            if (options.action.toString().toLowerCase() === "js") {
                try {
                    eval(decodeURIComponent(options.js))
                } catch (e) {
                    console.error(e)
                }
            } else if (options.action.toString().toLowerCase() === "url_self") {
                window.location.href = options.url;
            } else if (options.action.toString().toLowerCase() === "url_blank") {
                window.open(options.url, '_blank');
            }
        })
        videojs.dom.emptyEl(this.el());
    }

    transform(currentDimensions) {
        this.el().style.left = (currentDimensions.width * this.xMultiplier) + "px";
        this.el().style.top = (currentDimensions.height * this.yMultiplier) + "px";
        this.dimensions((currentDimensions.width * this.widthMultiplier), (currentDimensions.height * this.heightMultiplier))
    }

    createEl() {
        return videojs.dom.createEl('div', {
            className: 'vjs-treepodia-overlay-btn',
        });
    }
}
