import {allocate, API_EVENTS, MyTreepodiaTracker} from "./lib/my-treepodia";
import {VideoJS} from "./lib/VideoJS";
import EventEmitter from 'eventemitter3';
import * as pkg from '../package.json';
import {GoogleAnalyticsTracker} from "./lib/google-analytics-tracker";


export const TreepodiaPlayerEvents = {
    READY: 'player.ready',
    OVERLAY_CLICK: 'overlay.click'
}

export class TreepodiaPlayer extends EventEmitter {
    _logMessages = [];
    _version = pkg.version

    constructor() {
        super();
        console.info(`%c [==> Treepodia Player Ver. ${this._version} https://treepodia.com <==]`, 'background: #222; color: #8EB53E');
        this._initTrackers()
            .then(this._processPlayerConfig.bind(this))
            .then(this._injectCSS.bind(this))
            .then(this._createDefaultPlayer.bind(this))
            .catch(reason => {
                this._log(reason)
                if (this._playerConfig && this._playerConfig.onError) {
                    try {
                        this._playerConfig.onError(reason)
                    } catch (e) {
                        this._log('onError: error');
                    }
                }
            })
    }

    _log(msg) {
        if (msg) this._logMessages.push(msg)
        return this
    }

    report() {
        console.log(this._logMessages)
    }

    async _initTrackers() {
        try {
            this.mtTracker = new MyTreepodiaTracker();
            this.gaTracker = new GoogleAnalyticsTracker();
        } catch (e) {
            this._log(e)
        }
    }

    async _injectCSS() {
        return new Promise((resolve) => {
            if (process.env.NODE_ENV === 'production') {
                const css = document.createElement('link');
                css.rel = 'stylesheet';
                css.href = '//dxa05szpct2ws.cloudfront.net/player3/TreepodiaPlayer.css';
                document.head.appendChild(css)
                css.onload = () => {
                    resolve()
                }
            } else {
                resolve()
            }
        })
    }

    async _injectCustomCSS(href) {
        const customCss = document.createElement('link');
        customCss.rel = 'stylesheet';
        customCss.href = href;
        document.head.appendChild(customCss)
    }

    _setupPopupButton() {
        this._popupModal = document.createElement('div');
        this._popupModal.className = 'vjs-treepodia-modal';
        this._popupModal.onclick = (e) => {
            if (e.target === this._popupModal) {
                this._player.pause();
                this._popupModal.style.display = "none";
            }
        }

        this._popupModalContent = document.createElement('div');
        this._popupModalContent.className = 'vjs-treepodia-modal-content';

        this._popupModalContent.appendChild(this._videoElement)
        this._popupModal.appendChild(this._popupModalContent)
        document.body.appendChild(this._popupModal)

        this._popupButton = document.createElement("button");
        this._popupButton.className = 'vjs-treepodia-modal-btn trpd-popup-btn';
        if (this._playerConfig.popupBtnWidth && typeof this._playerConfig.popupBtnWidth === 'number' && this._playerConfig.popupBtnWidth > 0) {
            this._popupButton.style.width = this._playerConfig.popupBtnWidth + 'px';
        }
        if (this._playerConfig.popupBtnHeight && typeof this._playerConfig.popupBtnHeight === 'number' && this._playerConfig.popupBtnHeight > 0) {
            this._popupButton.style.height = this._playerConfig.popupBtnHeight + 'px';
        }

        if (this._playerConfig.popupBtnImg && typeof this._playerConfig.popupBtnImg === 'string' && this._playerConfig.popupBtnImg !== "") {
            this._popupButton.style.backgroundImage = `url(${this._playerConfig.popupBtnImg})`;
        }

        this._popupButton.onclick = () => {
            this._popupModal.style.display = "block";
            if (this._playerConfig.autoplay) this._player.play();
        }
        document.getElementById(this._playerConfig.container).appendChild(this._popupButton)
    }

    async _processPlayerConfig() {
        if (!window.initTreepodia) throw 'initTreepodia() is missing';
        this._log('initTreepodia: ok')
        window.initTreepodia({
            show: (playerConfig) => {
                this._playerConfig = playerConfig
                if (!this._playerConfig) throw 'Video config is empty';
                this._log('VideoConfig: ok')
            }
        })

        if (!this._isTreepodiaUuid(this._playerConfig.account)) throw 'UUID missing or wrong'
        this._log('VideoConfig:UUID: ok')

        if (!this._isValidSku(this._playerConfig.sku)) throw 'SKU missing or wrong'
        this._log('VideoConfig:SKU: ok')

        if (!this._isValidContainer(this._playerConfig.container)) throw 'Container missing or wrong';
        // document.
        this._log('VideoConfig:Container: ok')

        this.mtTracker.setAccount(this._playerConfig.account).setSku(this._playerConfig.sku);
        this.gaTracker.setAccount(this._playerConfig.account).setSku(this._playerConfig.sku)
            .setEnabled(this._playerConfig.gaEnabled)
            .setGaId(this._playerConfig.gaId);

        await allocate(this._playerConfig.account, this._playerConfig.sku, this._playerConfig.authToken).then((allocationData) => {
            this._videoConfig = allocationData;
            if (this._videoConfig && this._videoConfig.video && this._videoConfig.video.videoURL && this._videoConfig.video.videoId) {
                this.gaTracker.setVideoId(this._videoConfig.video.videoId);
                this.mtTracker.setVideoId(this._videoConfig.video.videoId)
                    .sendEvent(API_EVENTS.PAGE_VIEW);
            } else {
                this.mtTracker.sendEvent(API_EVENTS.PAGE_VIEW)
                throw 'Video not available'
            }
            this._log('Video: ok')
        })

        this._videoElement = document.createElement("video-js")
        this._videoElement.className = 'video-js vjs-treepodia';

        if (this._playerConfig.popup) {
            this._setupPopupButton();
        } else {
            document.getElementById(this._playerConfig.container).appendChild(this._videoElement)
        }

        this._videoJSConfig = {
            bigPlayButton: false,
            controls: this._playerConfig.controls === undefined ? true : this._playerConfig.controls,
            autoplay: (this._playerConfig.autoplay === undefined || this._playerConfig.popup) ? false : this._playerConfig.autoplay,
            loop: this._playerConfig.loop === undefined ? false : this._playerConfig.loop,
            muted: this._playerConfig.muted === undefined ? false : this._playerConfig.muted,
            playsinline: this._playerConfig.playsinline === undefined ? true : this._playerConfig.playsinline,
            preload: this._playerConfig.preload === undefined ? 'metadata' : this._playerConfig.preload,
            width: this._playerConfig.width === undefined ? this._videoConfig.video.width : this._playerConfig.width,
            height: this._playerConfig.height === undefined ? this._videoConfig.video.height : this._playerConfig.height,
            fluid: (function (responsive, width, height, vWidth, vHeight) {
                if (responsive !== undefined) return responsive;
                return width === undefined || height === undefined || (width === vWidth && height === vHeight) || (width / height === vWidth / vHeight);
            })(this._playerConfig.responsive, this._playerConfig.width, this._playerConfig.height, this._videoConfig.video.width, this._videoConfig.video.height),
            sources: [{
                src: this._videoConfig.video.videoURL,
                type: 'video/mp4'
            }],
            plugins: {
                treepodiaEventsPlugin: {
                    mtTracker: this.mtTracker,
                    gaTracker: this.gaTracker
                },
                treepodiaButtonsPlugin: {
                    overlays: ((playerOverlays, videoOverlays) => {
                        let overlays = [];
                        if (videoOverlays && Array.isArray(videoOverlays) && videoOverlays.length > 0) overlays = overlays.concat(videoOverlays)
                        if (playerOverlays && Array.isArray(playerOverlays) && playerOverlays.length > 0) overlays = overlays.concat(playerOverlays)
                        return overlays;
                    })(this._playerConfig.overlays, this._videoConfig.overlays),
                    videoSize: {width: this._videoConfig.video.width, height: this._videoConfig.video.height},
                },
            },
        }

        if (this._playerConfig.showPoster === undefined || this._playerConfig.showPoster === true) {
            if (this._playerConfig.posterUrl) {
                this._videoJSConfig.poster = this._playerConfig.posterUrl;
                this._log('Poster: ok: user')
            } else if (this._videoConfig.video.videoSnapshotUrl) {
                this._videoJSConfig.poster = this._videoConfig.video.videoSnapshotUrl;
                this._log('Poster: ok: api')
            } else {
                this._log('Poster: trying but no')
            }
        }

        if (this._playerConfig.customCSS) {
            this._log('customCss no longer supported');
            await this._injectCustomCSS(this._playerConfig.customCSS)
        }

    }

    async _createDefaultPlayer() {
        this._player = VideoJS(this._videoElement, this._videoJSConfig);

        const extremeLogoAccounts = ["UA-SHUFER-SAL", "UA-HOT"];
        if (extremeLogoAccounts.indexOf(this._playerConfig.account) !== -1) {
            this._player.addChild('treepodiaLogoExtreme');
        }

        const secretAnalyticsAccounts = [{
            account: "UA-SHUFER-SAL",
            gaId: "G-EMWMRFGDEY"
        }]

        for (let i = 0; i < secretAnalyticsAccounts.length; i++) {
            if (this._playerConfig.account === secretAnalyticsAccounts[i].account) {
                this.gaTracker.setGaId(secretAnalyticsAccounts[i].gaId)
            }
        }


        if (this._videoConfig.video.showTreepodiaLogo) {
            this._player.getChild('ControlBar').addChild('treepodiaLogo')
        }

        // console.log(this._player.getChild('BigPlayButton'))
        this._player.addChild('treepodiaPlayButtonComponent', {blue: false});
        if (this._playerConfig.showJumboPlay !== undefined && this._playerConfig.showJumboPlay === false) {
            this._player.removeChild('treepodiaPlayButtonComponent');
        }
        if (this._playerConfig.blueJumboPlay) {
            if (this._player.getChild('treepodiaPlayButtonComponent')) {
                this._player.removeChild('treepodiaPlayButtonComponent');
            }
            this._player.addChild('treepodiaPlayButtonComponent', {blue: true});
            //
        }
        //

        if (this._playerConfig.pictureInPicture === undefined || this._playerConfig.pictureInPicture === false) {
            this._player.getChild('ControlBar').removeChild('PictureInPictureToggle');
        }

        if (this._videoJSConfig.autoplay) {
            this._player.muted(true);
        }

        this._player.ready(() => {
            this._log('Player Ready')
            if (this._playerConfig.onReady) {
                try {
                    this._playerConfig.onReady(this)
                } catch (e) {
                    this._log('onReady: error');
                }
            }
        })
    }

    _isTreepodiaUuid(test) {
        return test && typeof test === "string" && test !== '' && test.indexOf('UA-') !== -1;
    }

    _isValidSku(test) {
        return test && typeof test === "string" && test !== '';
    }

    _isValidContainer(test) {
        return test && typeof test === "string" && test !== '' && document.getElementById(test) !== null;
    }

    logAddToCart(accountOrObj, sku) {
        if (typeof accountOrObj === "object" && accountOrObj.account && accountOrObj.account !== '' && accountOrObj.sku && accountOrObj.sku !== '') {
            accountOrObj = accountOrObj.account;
            sku = accountOrObj.sku;
        }
        if (this._isTreepodiaUuid(accountOrObj) && sku && sku !== '') {
            if (this._videoConfig && this._videoConfig.video && this._videoConfig.video.videoId) {
                this.mtTracker.sendEvent(accountOrObj, sku, API_EVENTS.ADD_TO_CART, this._videoConfig.video.videoId)
                    .catch(this._log.bind(this))
            } else {
                this.mtTracker.sendEvent(accountOrObj, sku, API_EVENTS.ADD_TO_CART)
                    .catch(this._log.bind(this))
            }
        }

    }

    async trackVideoShare() {
    }

    async sendCustomEvent() {
    }

    //TODO
    // async createPlayer() {}
}
