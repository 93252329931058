import videojs from "video.js";

const Component = videojs.getComponent('BigPlayButton');
const playButton = require('../../assets/playButton.png');
const playButtonBlue = require('../../assets/playButtonBlue.png');

export class TreepodiaPlayButtonComponent extends Component {
    constructor(player, options) {
        super(player, options);
        this.removeClass('vjs-big-play-button');
        this.addClass('vjs-treepodia-big-play-button');
        // this.addClass('vjs-big-play-centered');
        this.centerd = true;
        const link = document.createElement('img');
        link.src = options.blue ? playButtonBlue : playButton;
        // link.href = 'https://treepodia.com?utm_source=html5_player';
        // link.target = "_blank";
        // link.innerText = "Treepodia"
        //
        // // videojs.dom.appendContent(this.el(), );
        // videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), link);
        // videojs.dom.appendContent(this.el(), link);
    }
    //
    // createEl() {
    //     return videojs.dom.createEl('button', {
    //         className: 'vjs-trepodia-big-play-button'
    //     });
    // }
}

