import videojs from "video.js";

const Plugin = videojs.getPlugin('plugin');

export class TreepodiaButtonsPlugin extends Plugin {
    constructor(player, options) {
        super(player, options);

        if (options.overlays && Array.isArray(options.overlays) && options.overlays.length > 0) {
            player.on('loadedmetadata', () => {
                this.overlayButtons = options.overlays.map((overlayBtnConfig, i) => {
                    overlayBtnConfig.videoSize = options.videoSize;
                    overlayBtnConfig.id = overlayBtnConfig.id === undefined ? i : overlayBtnConfig.id;
                    return player.addChild('treepodiaOverlayButton', overlayBtnConfig)
                })
            });

            player.on('playerresize', () => {
                if (this.overlayButtons && this.overlayButtons.length > 0) {
                    this.overlayButtons.forEach((overlayButton) => {
                        overlayButton.transform(player.currentDimensions())
                    })
                }
            });


            player.on('timeupdate', () => {
                if (this.overlayButtons && this.overlayButtons.length > 0) {
                    const currentTime = Math.round(player.currentTime())
                    this.overlayButtons.forEach((overlayButton) => {
                        if (currentTime >= overlayButton.startTime && currentTime <= overlayButton.endTime) {
                            overlayButton.show()
                        } else {
                            overlayButton.hide();
                        }
                    })
                }
            });
        }

    }
}
