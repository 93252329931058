import videojs from "video.js";

const Component = videojs.getComponent('Component');

export class TreepodiaExtremeLogoComponent extends Component {
    constructor(player) {
        super(player);
        const link = document.createElement('a');
        link.href = 'https://treepodia.com?utm_source=html5_player';
        link.target = "_blank";
        link.innerText = "Treepodia"

        // videojs.dom.appendContent(this.el(), );
        videojs.dom.emptyEl(this.el());
        videojs.dom.appendContent(this.el(), "Powered by ");
        videojs.dom.appendContent(this.el(), link);
    }

    createEl() {
        return videojs.dom.createEl('div', {
            className: 'vjs-treepodia-logo-extreme'
        });
    }
}

