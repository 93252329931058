import fetch from 'cross-fetch';
import * as uuid from "uuid";

// export const tsid = (function () {
//     let d = new Date().getTime();
//     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
//         let r = (d + Math.random() * 16) % 16 | 0;
//         d = Math.floor(d / 16);
//         return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
//     });
// })();

export const API_EVENTS = {
    PAGE_VIEW: "page-loaded",
    PLAYER_PLAY: "Player-Play",
    PLAYER_RESUME: "Player-Resume",
    PLAYER_PAUSE: "Player-Pause",
    PLAYER_END: "Player-End",
    PLAYER_25: "Player-First-Quartile",
    PLAYER_50: "Player-Midpoint",
    PLAYER_75: "Player-Third-Quartile",
    ADD_TO_CART: "add-to-cart",
    VIDEO_SHARE: "video-shared",
    OVERLAY_CLICK: "Overlay-Button-Click"
}

export async function allocate(account, sku, authToken) {
    const allocateUrl = `${window.location.protocol}//api.treepodia.com/video/allocate?account=${account}&sku=${sku}&includeVideoSnapshot=true`;
    const init =  authToken ? {
        method: "GET",
        headers: {
            Authorization: 'Bearer ' + authToken
        },
        'credentials': 'include'
    } : {
        method: "GET",
        'credentials': 'include'
    };

    const res = await fetch(allocateUrl, init);
    return await res.json()
}

// export async function logEvent(account, sku, type, videoId) {
//     const cookies = Cookies;
//     const tsid = cookies.get('_trpdsid');
//     let eventsUrl = `${window.location.protocol}//api.treepodia.com/video/logEvent?tsid=${tsid}&account=${account}&sku=${sku}&type=${type}`;
//     if (videoId) {
//         eventsUrl += `&videoId=${videoId}`
//     }
//     const res = await fetch(eventsUrl);
//     return await res.json()
// }

export class MyTreepodiaTracker {

    constructor() {
        this.tsid = uuid.v4();
        this.account = null;
        this.sku = null;
        this.videoId = null;
    }

    setAccount(account) {
        this.account = account;
        return this
    }

    setSku(sku) {
        this.sku = sku;
        return this
    }

    setVideoId(videoId) {
        this.videoId = videoId;
        return this
    }

    async sendEvent(type) {
        let eventsUrl = `${window.location.protocol}//api.treepodia.com/video/logEvent?tsid=${this.tsid}&account=${this.account}&sku=${this.sku}&type=${type}`;
        if (this.videoId !== null) {
            eventsUrl += `&videoId=${this.videoId}`
        }
        const res = await fetch(eventsUrl, {
            method: "GET",
            'credentials': 'include'
        });
        return await res.json()
    }

}


