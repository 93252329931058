import videojs from 'video.js';
import {TreepodiaLogoComponent} from "../components/js/TreepodiaLogoComponent";
import {TreepodiaButtonsPlugin} from "../plugins/TreepodiaButtonsPlugin";
import {TreepodiaOverlayButtonComponent} from "../components/js/TreepodiaOverlayButtonComponent";
import {TreepodiaEventsPlugin} from "../plugins/TreepodiaEventsPlugin";
import {TreepodiaExtremeLogoComponent} from "../components/js/TreepodiaExtremeLogoComponent";
import {TreepodiaPlayButtonComponent} from "../components/js/TreepodiaPlayButtonComponent";

videojs.registerPlugin('treepodiaEventsPlugin', TreepodiaEventsPlugin);
videojs.registerPlugin('treepodiaButtonsPlugin', TreepodiaButtonsPlugin);

videojs.registerComponent('treepodiaLogo', TreepodiaLogoComponent);
videojs.registerComponent('treepodiaLogoExtreme', TreepodiaExtremeLogoComponent);
videojs.registerComponent('treepodiaOverlayButton', TreepodiaOverlayButtonComponent);
videojs.registerComponent('treepodiaPlayButtonComponent', TreepodiaPlayButtonComponent);

exports.VideoJS = videojs
